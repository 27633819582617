import React from "react";
import { FaPhone } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaFacebookMessenger } from "react-icons/fa6";

function Footer({ user }) {
  return (
    <>
      <footer className="footer">
        <section className="footer-template" id="contacto">
          <div className="container-template">
            <div className="row">
              <div className="col-md-6">
                <h3 className="title-footer color-green">
                  información de contacto
                </h3>
                <p className="paragraph-footer">
                  <span className="span-market">
                    <FaMapMarkerAlt className="color-green" />
                  </span>
                  {user.address}, No.{user.outdoor_number}
                  <br />
                  Col. {user.suburb} <br />
                  C.P. {user.postal_code}, {user.state_name}, {user.city_name}
                </p>
                <iframe
                  className="iframe"
                  src={user.google_map}
                  width="432"
                  height="200"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
              <div className="col-md-6">
                <p className="paragraph-envelope">
                  <span className="span-envelope">
                    <FaEnvelope className="color-green" />
                  </span>
                  <a
                    href={"mailto:" + user.agency_mail}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {user.agency_mail}
                  </a>

                  {user.other_agency_mail != null && (
                    <>
                      {" "}
                      <br />
                      <span className="span-envelope">
                        <FaEnvelope className="color-green" />
                      </span>
                      <a
                        href={"mailto:" + user.other_agency_mail}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {user.other_agency_mail}
                      </a>
                    </>
                  )}
                </p>

                <p className="paragraph-mobile">
                  {" "}
                  <span className="span-mobile">
                    <FaPhone className="color-green" />
                  </span>{" "}
                  <a href={"tel:" + user.phone} target="_blank">
                    {" "}
                    {user.phone}{" "}
                  </a>{" "}
                </p>

                <ul className="group-xs group-middle social-icons-list">
                  {user.facebook != null ? (
                    <li className="footer-redes-li">
                      <a
                        className="icon novi-icon icon-md-smaller icon-circle icon-secondary-5-filled mdi mdi-facebook footer-redes-a"
                        href={"https://www.facebook.com/" + user.facebook}
                        target="_blank"
                      >
                        <FaFacebook size={25} className="icons" />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  {user.twitter != null ? (
                    <li className="footer-redes-li">
                      <a
                        className="icon novi-icon icon-md-smaller icon-circle icon-secondary-5-filled mdi mdi-twitter footer-redes-a"
                        href={"https://twitter.com/" + user.twitter}
                        target="_blank"
                      >
                        <FaXTwitter size={25} className="icons" />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {user.instagram != null ? (
                    <li className="footer-redes-li">
                      <a
                        className="icon novi-icon icon-md-smaller icon-circle icon-secondary-5-filled mdi mdi-instagram footer-redes-a"
                        href={"https://www.instagram.com/" + user.instagram}
                        target="_blank"
                      >
                        <FaInstagram size={25} className="icons" />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  {user.whatsapp != null ? (
                    <li className="footer-redes-li">
                      <a
                        className="icon novi-icon icon-md-smaller icon-circle icon-secondary-5-filled mdi mdi-whatsapp footer-redes-a"
                        href={
                          "https://api.whatsapp.com/send?phone=52" +
                          user.whatsapp
                        }
                        target="_blank"
                      >
                        <FaWhatsapp size={25} className="icons" />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {user.messenger != null ? (
                    <li className="footer-redes-li">
                      <a
                        className="icon novi-icon icon-md-smaller icon-circle icon-secondary-5-filled mdi mdi-messenger footer-redes-a"
                        href={"https://m.me/" + user.messenger}
                        target="_blank"
                      >
                        <FaFacebookMessenger size={25} className="icons" />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>

                <h5 className="title-form color-green">Escríbenos</h5>
                <form
                  method="post"
                  action="https://www.scriptsexodus.exodus.mx/send-template.php"
                  className="form-footer"
                >
                  <div className="form-group form-group-footer row">
                    <label
                      for=""
                      className="col-sm-2 col-form-label label-footer "
                    >
                      Nombre
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control input-footer green-input"
                        placeholder="Nombre*"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group form-group-footer row">
                    <label
                      for=""
                      className="col-sm-2 col-form-label label-footer"
                    >
                      Asunto
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name="asunto"
                        className="form-control input-footer green-input"
                        placeholder="Asunto*"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group form-group-footer row">
                    <label
                      for=""
                      className="col-sm-2 col-form-label label-footer"
                    >
                      E-mail
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="email"
                        name="email"
                        className="form-control input-footer green-input"
                        placeholder="Email*"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group form-group-footer row">
                    <label
                      for=""
                      className="col-sm-2 col-form-label label-footer"
                    >
                      Tel:
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name="telefono"
                        className="form-control input-footer green-input"
                        placeholder="telefono*"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label-footer">Mensaje</label>
                    <textarea
                      name="mensaje"
                      className="form-control textarea-footer"
                      required
                    ></textarea>
                  </div>
                  <div className="buttons-see text-right">
                    <input
                      type="hidden"
                      name="agencia"
                      value={user.agency_mail}
                    />
                    <input type="hidden" name="url" value={user.url} />
                    <input
                      type="hidden"
                      name="recaptcha_response"
                      id="recaptchaResponse"
                    ></input>
                    <button
                      type="submit"
                      className="button-template button-green"
                    >
                      Enviar Ahora
                    </button>
                  </div>
                </form>
              </div>

              <div className="col-md-12">
                <p className="meca">2021 all rights reserved</p>
              </div>
            </div>
          </div>
          {/*  <a href={'https://api.whatsapp.com/send?phone=+52'+user.whatsapp} target="_blank">
        <div className="writing"> Escríbenos por WhatsApp </div>
        <img className="icon-whats" src="https://exodus.b-cdn.net/exodus/magno/03/img/whatsapp.png" />
    </a>*/}
        </section>
      </footer>
    </>
  );
}

export default Footer;
